import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import parse from 'html-react-parser';
import SEO from '../components/SEO';
import Header from '../components/Header';

const NewsItemContainer = styled.div`
  a {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
  }

  .news-content-wrapper {
    margin-left: 1rem;
  }

  @media (max-width: 767px) {
    a {
      flex-direction: column;
    }
    .news-content-wrapper {
      margin-top: 1rem;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  .photo-copyright {
    position: absolute;
    bottom: 0;
    left: 1rem;
    z-index: 2;
    font-size: 9px;
    color: #fff;
    text-decoration: none;
  }
  @media (max-width: 767px) {
    .gatsby-image-wrapper {
      width: 100% !important;
    }
    .photo-copyright {
      left: 2rem;
    }
  }
  @media (max-width: 320px) {
    .photo-copyright {
      bottom: 1rem;
    }
  }
`;

function newsItem(items) {
  return items.map((item) => (
    <>
      <NewsItemContainer key={item.id}>
        <Link to={item.slug.current}>
          {item.mainImage ? (
            <ImageContainer>
              <GatsbyImage
                fixed={item.mainImage.asset.fixed}
                alt={item.title}
                imgStyle={{
                  objectFit: 'contain',
                }}
              />
              <p className="photo-copyright">{parse(item.imageAttribution)}</p>
            </ImageContainer>
          ) : (
            ''
          )}

          <div className="news-content-wrapper">
            <h2>{item.newsTitle}</h2>
            <p>{item.publishDate}</p>
            <p>{item.shortDescription}</p>
          </div>
        </Link>
      </NewsItemContainer>
      <hr />
    </>
  ));
}

export default function News({ location }) {
  const data = useStaticQuery(graphql`
    query NewsPageQuery {
      newsSettings: allSanityNewsPage {
        nodes {
          headline
          body
        }
      }
      newsItems: allSanityNews {
        nodes {
          id
          title
          newsTitle
          publishDate
          slug {
            current
          }
          shortDescription
          body
          mainImage {
            asset {
              fixed(width: 300) {
                ...GatsbySanityImageFixed
              }
            }
          }
          imageAttribution
        }
      }
    }
  `);
  const settings = data.newsSettings.nodes[0];
  const newsItems = data.newsItems.nodes;
  return (
    <>
      <SEO location={location} title="In the news" />
      <Header uniqueHeadline={settings.headline} />
      <main>
        <section className="center">
          <h1>{parse(settings.headline)}</h1>
        </section>
        <p>{parse(settings.body)}</p>
        {newsItems.length > 0 ? newsItem(newsItems) : 'Coming Soon'}
      </main>
    </>
  );
}
